<template>
  <div>
    <div class = "row">
      <div class = "col-md-6">
        <Input
            v-model = "search.search_data"
            style = "max-width: 350px"
            class = "d-inline-block mb-2"
            placeholder = "Search by name, email, phone..."
            type = "text"
        />
      </div>
      <div class = "col-md-6 text-right">
        <div class = "d-flex justify-content-end">
          <div
              class = "form-group d-flex align-items-center justify-content-start mb-md-0"
          >
            <label class = "mr-1">Show </label>
            <Select
                style = "margin-right: 10px"
                @on-change = "paginateChange"
                v-model = "search.paginate"
                :clearable = "false"
            >
            <Option :value = "10"> 10</Option>
            <Option :value = "30"> 30</Option>
            <Option :value = "50"> 50</Option>
            <Option :value = "80"> 80</Option>
            <Option :value = "100"> 100</Option>
            </Select>
          </div>
          <Button @click = "Filtering">Advance Filtering</Button>
        </div>
      </div>
    </div>
    <b-card no-body>
      <b-card-body>
        <b-card-title class = "d-flex justify-content-between">
          <h3>Candidate Lists</h3>
          <b-button variant = "primary" @click = "candidateAddModal">Add</b-button>
          <Modal
              v-model = "candidate_add_modal"
              :footer-hide = "true"
              :title = "(form.id?'Update':'Add New') + ' Candidate'"
          >
            <b-form @submit.prevent = "form.id ?UpdateCandidate():CreateCandidate()">
              <div class = "form-group">
                <label> Name </label>
                <Input
                    v-model = "form.name"
                    placeholder = "Enter candidate name"
                    style = "width: 100%"
                    type = "text"
                />
                <has-error :form = "form" field = "name"></has-error>
              </div>
              <div class = "form-group">
                <label> Phone </label>
                <Input
                    v-model = "form.phone"
                    placeholder = "Enter candidate phone number"
                    style = "width: 100%"
                    type = "text"
                />
                <has-error :form = "form" field = "phone"></has-error>
              </div>
              <div class = "form-group">
                <label> Email </label>
                <Input
                    v-model = "form.email"
                    placeholder = "Enter candidate email"
                    style = "width: 100%"
                    type = "text"
                />
                <has-error :form = "form" field = "email"></has-error>
              </div>
              <div class = "form-group">
                <label> Password </label>
                <Input
                    v-model = "form.password"
                    placeholder = "Enter candidate password"
                    style = "width: 100%"
                    type = "text"
                />
                <has-error :form = "form" field = "password"></has-error>
              </div>
              <div class = "d-flex align-items-center mt-2"
                   :class = "form.id?'justify-content-between':'justify-content-end'"
              >
                <div v-if = "form.id">
                  <b-form-checkbox
                      name = "status"
                      style = "margin-top: 5px"
                      v-model = "form.status"
                      switch
                      inline
                  />
                </div>
                <div>
                  <b-button
                      variant = "primary"
                      type = "submit"
                      :disabled = "form.busy"
                  >
                    {{ form.id ? "Update" : "Add" }}
                  </b-button>
                </div>
              </div>
            </b-form>
          </Modal>
        </b-card-title>
        <b-row class = "justify-content-between" v-if = "filter_status">
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Select
                  v-model = "search.status"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Status"
              >
              <Option :value = "1"> Active</Option>
              <Option :value = "2"> Inactive</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Select
                  v-model = "search.gender"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Gender"
              >
              <Option value = "Male"> Male</Option>
              <Option value = "Female"> Female</Option>
              <Option value = "others"> Other</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Select
                  v-model = "search.blood_group"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Blood
              Group"
              >
              <Option value = "A+"> A+</Option>
              <Option value = "B+"> B+</Option>
              <Option value = "A-"> A-</Option>
              <Option value = "B-"> B-</Option>
              <Option value = "AB+"> AB+</Option>
              <Option value = "AB-"> AB-</Option>
              <Option value = "O+"> O+</Option>
              <Option value = "O-"> O-</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "3">
            <div class = "form-group">
              <Select
                  v-model = "search.onboard_status"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Onboard
              Status"
              >
              <Option :value = "1"> Complete</Option>
              <Option :value = "2"> Incomplete</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "3">
            <div class = "form-group">
              <Select
                  v-model = "search.is_approved"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Approval
              Status"
              >
              <Option :value = "1"> Approved</Option>
              <Option :value = "2"> Pending</Option>
              <Option :value = "3"> Rejected</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Select
                  v-model = "search.availability"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Work
              Availability"
              >
              <Option value = "UNAVAILABLE"> UNAVAILABLE</Option>
              <Option value = "FULLTIME"> FULLTIME</Option>
              <Option value = "PARTTIME"> PARTTIME</Option>
              <Option value = "HOURLY"> HOURLY</Option>
              <Option value = "CONTRACTUAL"> CONTRACTUAL</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Select
                  v-model = "search.seniority"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select seniority"
              >
              <Option value = "ENTRY"> ENTRY</Option>
              <Option value = "MID"> MID</Option>
              <Option value = "SENIOR"> SENIOR</Option>
              <Option value = "MANAGER"> MANAGER</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Select
                  v-model = "search.workplace_type"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Work Place Type"
              >
              <Option value = "REMOTE"> REMOTE</Option>
              <Option value = "ONSITE"> ONSITE</Option>
              <Option value = "HYBRID"> HYBRID</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "3">
            <div class = "form-group">
              <Input
                  v-model = "search.from_duration"
                  class = "d-inline-block mr-1"
                  placeholder = "From Experience"
                  type = "text"
              />
            </div>
          </b-col>
          <b-col cols = "6" lg = "3">
            <div class = "form-group">
              <Input
                  v-model = "search.to_duration"
                  class = "d-inline-block mr-1"
                  placeholder = "To Experience"
                  type = "text"
              />
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Select
                  v-model = "search.skill_filter_type"
                  @on-change = "skillFilter"
                  :clearable = "true"
                  placeholder = "Skill Filter Type"
              >
              <Option :value = "1"> AND</Option>
              <Option :value = "2"> OR</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "6">
            <div class = "form-group">
              <Select
                  :disabled = "!search.skill_filter_type"
                  multiple
                  v-model = "search.skill_id"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Skill"
              >
              <Option
                  v-for = "(skill, index) in skills"
                  :key = "index"
                  :value = "skill.id"
                  v-if = "skills.length"
              >{{ skill.name }}
              </Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "12" lg = "2">
            <div class = "form-group">
              <DatePicker
                  v-model = "from_date"
                  type = "date"
                  placeholder = "Candidate Created From Date"
                  style = "width: 100%"
              >
              </DatePicker>
            </div>
          </b-col>

          <b-col cols = "12" lg = "2">
            <div class = "form-group">
              <DatePicker
                  v-model = "to_date"
                  type = "date"
                  placeholder = "Candidate Created To Date"
                  style = "width: 100%"
              >
              </DatePicker>
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Input
                  v-model = "search.from_expected_salary"
                  class = "d-inline-block mr-1"
                  placeholder = "From Expected Salary"
                  type = "text"
              />
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Input
                  v-model = "search.to_expected_salary"
                  class = "d-inline-block mr-1"
                  placeholder = "To Expected Salary"
                  type = "text"
              />
            </div>
          </b-col>
          <b-col cols = "6" lg = "4">
            <div class = "form-group">
              <Select
                  multiple
                  v-model = "search.education_level_id"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Education Level"
              >
              <Option
                  v-for = "(educationLevel, index) in educationLevels"
                  :key = "index"
                  :value = "educationLevel.id"
                  v-if = "educationLevels.length"
              >{{ educationLevel.name }}
              </Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "4">
            <div class = "form-group">
              <Select
                  multiple
                  v-model = "search.industry_id"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Industry"
              >
              <Option
                  v-for = "(industry, index) in industries"
                  :key = "index"
                  :value = "industry.id"
                  v-if = "industries.length"
              >{{ industry.name }}
              </Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Select
                  v-model = "search.address_type"
                  @on-change = "getAddress"
                  :clearable = "true"
                  placeholder = "Address Type"
              >
              <Option :value = "1"> Present</Option>
              <Option :value = "2"> Permanent</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Select
                  :disabled = "!search.address_type"
                  v-model = "search.is_abroad"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Address"
              >
              <Option :value = "2"> Bangladesh</Option>
              <Option :value = "1"> Aboard</Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "2">
            <div class = "form-group">
              <Select
                  :disabled = "!search.address_type"
                  v-model = "search.division_id"
                  @on-change = "getDistrict"
                  :clearable = "true"
                  placeholder = "Select Division"
              >
              <Option
                  v-for = "(division, index) in divisions"
                  :key = "index"
                  :value = "division.id"
                  v-if = "divisions.length"
              >{{ division.name }}
              </Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "3">
            <div class = "form-group">
              <Select
                  :disabled = "
                  this.search.division_id == '' ||
                  this.search.division_id == null
                "
                  v-model = "search.district_id"
                  @on-change = "getUpazila"
                  :clearable = "true"
                  placeholder = "Select District"
              >
              <Option
                  v-for = "(district, index) in districts.data"
                  :key = "index"
                  :value = "district.id"
                  v-if = "districts.data.length"
              >{{ district.name }}
              </Option>
              </Select>
            </div>
          </b-col>
          <b-col cols = "6" lg = "3">
            <div class = "form-group">
              <Select
                  :disabled = "!search.district_id"
                  v-model = "search.upazila_id"
                  @on-change = "SearchData"
                  :clearable = "true"
                  placeholder = "Select Upazila"
              >
              <Option
                  v-for = "(upazila, index) in upazilas.data"
                  :key = "index"
                  :value = "upazila.id"
                  v-if = "upazilas.data.length"
              >{{ upazila.name }}
              </Option>
              </Select>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <div class = "table-responsive">
        <table class = "table">
          <thead>
          <tr>
            <th class = "text-center">Sl</th>
            <th class = "px-5">Photo</th>
            <th>Candidate Info</th>
            <th>DateTime</th>
            <th>Preference</th>
            <th>Skill</th>
            <th class = "text-center">Status</th>
            <th class = "text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr  v-if = "candidates.data.length"
              v-for = "(candidate, index) in candidates.data" :key = "index">
            <td class = "align-middle text-center">
              {{ candidates.meta.from + index }}
            </td>
            <td class = "align-middle text-center">
              <b
              ><img
                  style = "border-radius: 50%"
                  :src = "candidate.photo"
                  alt = "photo"
                  width = "70"
              /></b>
            </td>
            <td>
              <p><b>Name :</b> {{ candidate.name }}</p>
              <p><b>Email :</b> {{ candidate.email }}</p>
              <p><b>Phone :</b> {{ candidate.phone }}</p>
            </td>
            <td>
              <p v-if = "candidate.email_verified_at != null">
                <b>Verified At :</b> {{ candidate.email_verified_at }}
              </p>
              <p v-else>Not verified yet</p>
              <p><b>Created At :</b> {{ candidate.created_at }}</p>
            </td>
            <td v-if = "candidate.preference">
              <p>
                <b>Designation :</b>
                {{ candidate.preference.designation }}
              </p>
              <p><b>Seniority :</b> {{ candidate.preference.seniority }}</p>
            </td>
            <td v-else>No preference Yet</td>
            <td
                width = "20%"
                v-if = "candidate.skills && candidate.skills.length"
            >
                <span
                    v-for = "(skill, index) in candidate.skills"
                    :key = "index"
                    style = "margin-right: 2px; margin-bottom: 2px"
                    :class = "{
                    'badge badge-secondary': skill.proficiency === 'BEGINNER',
                    'badge badge-primary': skill.proficiency === 'EXPERIENCED',
                    'badge badge-info': skill.proficiency === 'ADVANCED',
                    'badge badge-success': skill.proficiency === 'EXPERT',
                  }"
                >
                  {{ skill.name + " (" + skill.proficiency + ") " }}
                </span>
            </td>
            <td v-else>No skills Yet</td>
            <td class = "align-middle text-center">
                <span
                    style = "margin-right: 2px"
                    :class = "
                    candidate.onboard_status !== 'Onboad Not Complete'
                      ? 'badge badge-primary'
                      : 'badge badge-danger'
                  "
                >
                  {{ candidate.onboard_status }}
                </span>

              <span
                  :class = "
                    candidate.status !== 'inactive'
                      ? 'badge badge-primary'
                      : 'badge badge-danger'
                  "
              >
                  {{ candidate.status }}
                </span>
            </td>
            <td>
              <Tooltip content = "edit" placement = "top-end">
                <b-button
                    @click = "editCandidate(candidate.id)"
                    variant = "primary"
                    size = "sm"
                    style = "margin-right: 5px"
                >
                  <feather-icon icon = "EditIcon"/>
                </b-button>
              </Tooltip>

              <Tooltip content = "view">
                <router-link
                    class = "btn btn-secondary btn-sm"
                    :to = "'/profile/' + candidate.id"
                >
                  <feather-icon icon = "EyeIcon"/>
                </router-link>
              </Tooltip>
            </td>
          </tr>

          <tr v-if="!candidates.data.length">
            <td class="text-center" colspan="6">
              <h4>No data found!</h4>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <b-card-body>
        <b-row>
          <b-col cols = "6">
            <span class = "text-muted"
            >Showing {{ candidates.meta.from }} to {{ candidates.meta.to }} of
              {{ candidates.meta.total }} entries</span
            >
          </b-col>
          <b-col cols = "6">
            <pagination
                class = "mb-0"
                :data = "candidates"
                @pagination-change-page = "getResults"
                align = "right"
                :show-disabled = "true"
                :limit = "5"
            ></pagination>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BPagination,
} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      search: {
        gender: "",
        blood_group: "",
        search_data: "",
        is_approved: "",
        seniority: "",
        workplace_type: "",
        from_duration: "",
        to_duration: "",
        from_expected_salary: "",
        to_expected_salary: "",
        skill_filter_type: "",
        skill_id: [],
        education_level_id: [],
        industry_id: [],
        from_date: "",
        to_date: "",
        is_abroad: "",
        address_type: "",
        division_id: "",
        district_id: "",
        upazila_id: "",
        onboard_status: "",
        paginate: 10,
        page: 1,
        status: "",
      },
      filter_status: false,
      from_date: "",
      to_date: "",
      candidates: [],
      skills: [],
      educationLevels: [],
      industries: [],
      candidate_add_modal: false,
      form: new Form({
        id: null,
        name: null,
        phone: null,
        email: null,
        password: null,
        status: true
      }),
    };
  },
  components: {
    BCard,
    BCardBody,
    BCardText,
    BLink,
    BMedia,
    BFormFile,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
    BPagination,
    HasError
  },
  mounted() {
    this.getResults();
    this.getSkills();
    this.getEducationLevels();
    this.getIndustries();
    this.getDivisions();
  },
  methods: {
    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null;
    },

    candidateAddModal() {
      this.Clear();
      this.candidate_add_modal = true;
    },

    Clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.phone = null;
      this.form.email = null;
      this.form.status = false;
    },
    CreateCandidate() {
      this.form
          .post("/app/registration")
          .then((res) => {
            this.s(res.data.message);
            this.getResults();
            this.candidate_add_modal = false;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    editCandidate(id) {
      axios
          .get("/app/candidate/" + id)
          .then((res) => {
            this.form.id = res.data.candidate.id;
            this.form.name = res.data.candidate.name;
            this.form.phone = res.data.candidate.phone;
            this.form.email = res.data.candidate.email;
            this.form.status = res.data.candidate.status == 1 ? true : false;
            this.candidate_add_modal = true;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    UpdateCandidate() {
      this.form
          .put("/app/candidate/" + this.form.id)
          .then((res) => {
            this.s(res.data.message);
            this.getResults();
            this.Clear();
            this.candidate_add_modal = false;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      this.search.from_date = this.from_date
          ? this.formatDate(this.from_date)
          : "";
      this.search.to_date = this.from_date ? this.formatDate(this.to_date) : "";
      const queryParams = this.buildQueryParamString(this.search);
      axios
          .get(`/app/candidate${queryParams}`)
          .then((res) => {
            this.candidates = res.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },
    getSkills() {
      axios
          .get("/app/get/skill")
          .then((res) => {
            this.skills = res.data.skills.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },
    getIndustries() {
      axios
          .get("/app/industry")
          .then((res) => {
            this.industries = res.data.industries.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },
    getEducationLevels() {
      axios
          .get("/app/level")
          .then((res) => {
            this.educationLevels = res.data.levels.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },
    getDivisions() {
      this.$store.dispatch("getDivision");
    },
    getDistrict() {
      this.search.district_id = null;
      this.$store.dispatch(
          "getDistrict",
          this.search.division_id ? "?division_id=" + this.search.division_id : ""
      );
      this.SearchData();
    },
    getUpazila() {
      this.search.upazila_id = null;
      this.$store.dispatch(
          "getUpazila",
          this.search.district_id ? "?district_id=" + this.search.district_id : ""
      );
      this.SearchData();
    },
    Filtering() {
      this.filter_status = !this.filter_status;
    },
    skillFilter() {
      if (
          this.search.skill_filter_type == null ||
          this.search.skill_filter_type == ""
      ) {
        this.search.skill_id = [];
      }
      if (this.search.skill_id.length) {
        this.SearchData();
      }
    },

    getAddress() {
      if (this.search.address_type == null || this.search.address_type == "") {
        this.search.is_abroad = null;
        this.search.division_id = null;
        this.search.district_id = null;
        this.search.upazila_id = null;
      }
      if (
          (this.search.division_id == null || this.search.division_id == "") &&
          (this.search.is_abroad == null || this.search.is_abroad == "")
      ) {
        this.SearchData();
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.level;
    },
    divisions() {
      return this.$store.getters.getDivision;
    },
    districts() {
      return this.$store.getters.getDistrict;
    },
    upazilas() {
      return this.$store.getters.getUpazila;
    },
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.from_duration": function (val) {
      this.SearchData();
    },
    "search.to_duration": function (val) {
      this.SearchData();
    },
    "search.from_expected_salary": function (val) {
      this.SearchData();
    },
    "search.to_expected_salary": function (val) {
      this.SearchData();
    },
    from_date: function (val) {
      this.SearchData();
    },
    to_date: function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
};
</script>
<style scoped>
table td {
  padding: 0.72rem 1rem;
}

.table th {
  padding: 0.72rem 1rem;
}

table td p {
  margin-bottom: 3px;
}
</style>
